import React from 'react';
import {DataTablePageParams, DataTableSortParams, DataTableSortOrderType} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {InputText} from 'primereact/inputtext';
import OrdersService from '../../services/OrdersService';
import {NavLink} from 'react-router-dom';
import {DateTime} from 'luxon';
import {Subscription} from 'rxjs';
import {Toast} from 'primereact/toast';
import {
  MessageService,
  TwoDataTable,
  AppColumnMenuBodyTemplate,
  ToastService,
  AppMenuItem,
  AppMenuItemTemplate,
  UsersService,
  DrawingsDialog,
  TempFile,
} from 'two-app-ui';
import {AppContext} from 'two-app-ui';
import DateColumnFilter, {DateColumnFilterChangeEvent} from '../DateColumnFilter/DateColumnFilter';
import {MultiSelect} from 'primereact/multiselect';
import {formats} from '../../config/formats';
import {
  QueryParameter,
  DropdownOption,
  ApiListResponse,
  Order,
  TleContentStageTransition,
  TimeLineEvent,
  OrderPatch,
  OrderStage,
  UserReference,
  Contact,
  PaOrderPatch,
  PaOrder,
  DrawingFileReference,
} from 'two-core';
import '../../scss/CustomTable.scss';
import {DropdownChangeParams} from 'primereact/dropdown';
import {InputSwitchChangeParams} from 'primereact/inputswitch';
import './OrderListComponent.scss';
import {messages} from '../../config/messages';
import {Tooltip} from 'primereact/tooltip';
import {MenuItemOptions} from 'primereact/menuitem';
import AddEditOrderDialog from '../Order/AddEditOrderDialog';
import SubmitOrderConfirmDialog from '../Order/SubmitOrderConfirmDialog';
import {orderStages} from '../../config/values';
import ContactsService from '../../services/ContactsService';
import {faFolderOpen} from '@fortawesome/pro-regular-svg-icons';

export type OrderListMode =
  | 'Estimate'
  | 'New'
  | 'Drawings'
  | 'Credit Check'
  | 'In Production'
  | 'In Shipping'
  | 'Delivered'
  | 'Repairs'
  | 'All';

interface Props {
  mode: OrderListMode;
}

interface State {
  editOrder: Order;
  loading: boolean;
  items: Order[];
  totalItems: number;
  selectedItems: Order[];
  activeFilters: {};
  availableOwners: UserReference[];
  filters: {
    order_code: string;
    reference: string;
    company_name: string;
    size: number | undefined;
    summary: string;
    stage: string;
    owner_ids: string[];
    created_at: {
      fromDate: DateTime | null;
      toDate: DateTime | null;
    };
    submitted_at: {
      fromDate: DateTime | null;
      toDate: DateTime | null;
    };
    approved_at: {
      fromDate: DateTime | null;
      toDate: DateTime | null;
    };
    ecd: {
      fromDate: DateTime | null;
      toDate: DateTime | null;
    };
    container_id: string;
    eta: {
      fromDate: DateTime | null;
      toDate: DateTime | null;
    };
    delivered_at: {
      fromDate: DateTime | null;
      toDate: DateTime | null;
    };
  };
  pagination: {
    pageSize: number;
    offset: number;
  };
  sortBy: {
    field: string;
    order: DataTableSortOrderType;
  } | null;
  error: boolean;
  showEditDialog: boolean;
  showSubmitOrderDialog: boolean;
  showDrawingsDialog: boolean;
  selectedOrder: Order;
}

class OrderListComponent extends React.Component<Props, State> {
  static contextType = AppContext;

  ordersService: OrdersService | null = null;
  toastService: ToastService | null = null;
  usersService: UsersService | null = null;
  contactsService: ContactsService | null = null;

  subscription: Subscription = new Subscription();

  toast: React.RefObject<Toast>;
  typingTimer: NodeJS.Timeout | undefined = undefined;

  //todo this variables should be moved to state like in OrderListComponent in made2order-web-client.
  availableStages: OrderStage[] = orderStages;
  colCreatedVisible = true;
  colSubmittedVisible = true;
  colApprovedVisible = true;
  colECDVisible = true;
  colContainerVisible = true;
  colETAVisible = true;
  colDeliveredVisible = true;

  constructor(props: Props) {
    super(props);
    this.state = {
      editOrder: new Order({}),
      items: [],
      totalItems: 0,
      loading: false,
      selectedItems: [],
      activeFilters: {},
      availableOwners: [],
      filters: {
        order_code: '',
        size: undefined,
        reference: '',
        company_name: '',
        stage: '',
        summary: '',
        owner_ids: [],
        created_at: {
          fromDate: null,
          toDate: null,
        },
        submitted_at: {
          fromDate: null,
          toDate: null,
        },
        approved_at: {
          fromDate: null,
          toDate: null,
        },
        ecd: {
          fromDate: null,
          toDate: null,
        },
        container_id: '',
        eta: {
          fromDate: null,
          toDate: null,
        },
        delivered_at: {
          fromDate: null,
          toDate: null,
        },
      },
      pagination: {
        pageSize: 25,
        offset: 0,
      },
      sortBy: null,
      error: false,
      showEditDialog: false,
      showSubmitOrderDialog: false,
      showDrawingsDialog: false,
      selectedOrder: new Order({}),
    };

    this.toast = React.createRef();

    this.onPageChange = this.onPageChange.bind(this);
    this.onSort = this.onSort.bind(this);
    this.setChangeSelectedItems = this.setChangeSelectedItems.bind(this);

    this.codeBodyTemplate = this.codeBodyTemplate.bind(this);
    this.stageBodyTemplate = this.stageBodyTemplate.bind(this);
    this.approvedAtBodyTemplate = this.approvedAtBodyTemplate.bind(this);
    this.ecdBodyTemplate = this.ecdBodyTemplate.bind(this);
    this.deliveredBodyTemplate = this.deliveredBodyTemplate.bind(this);

    this.onFilterChange = this.onFilterChange.bind(this);
    this.handleFilterChange = this.handleFilterChange.bind(this);
    this.createdAtBodyTemplate = this.createdAtBodyTemplate.bind(this);
    this.submittedAtBodyTemplate = this.submittedAtBodyTemplate.bind(this);
    this.onHideSubmitConfirmDialog = this.onHideSubmitConfirmDialog.bind(this);
    this.onHideDrawingDialog = this.onHideDrawingDialog.bind(this);
    this.showDrawingDialog = this.showDrawingDialog.bind(this);
    this.onDrawingsSave = this.onDrawingsSave.bind(this);
  }

  async componentDidMount() {
    this.ordersService = this.context.ordersService;
    this.toastService = this.context.toastService;
    this.usersService = this.context.usersService;
    this.contactsService = this.context.contactsService;

    this.modeSetup();

    this.subscription = MessageService.getMessage().subscribe(async message => {
      if (message === messages.orderUpdated) {
        this.loadData();
      }
    });

    this.loadData();
  }

  /** Sets up the available stages and column visibilities, starting with all cols visible and all
   * stages relevant.
   */
  modeSetup() {
    switch (this.props.mode) {
      case 'All':
      case 'Repairs':
        break;
      case 'Estimate':
        this.availableStages = ['Estimate', 'Repair Estimate'];
        this.colSubmittedVisible = false;
        this.colApprovedVisible = false;
        this.colECDVisible = false;
        this.colContainerVisible = false;
        this.colETAVisible = false;
        this.colDeliveredVisible = false;
        break;
      case 'New':
        this.availableStages = ['New'];
        this.colCreatedVisible = false;
        this.colApprovedVisible = false;
        this.colECDVisible = false;
        this.colContainerVisible = false;
        this.colETAVisible = false;
        this.colDeliveredVisible = false;
        break;
      case 'Drawings':
        this.availableStages = [
          'Drawing(s) Required',
          'Drawing Notes Awaiting Approval',
          'Drawing Notes Awaiting Approval Re-Draw',
          'Drawing(s) Awaiting Approval',
          'Re-Draw',
          'Drawing(s) Approved',
        ];
        this.colCreatedVisible = false;
        this.colApprovedVisible = false;
        this.colECDVisible = false;
        this.colContainerVisible = false;
        this.colETAVisible = false;
        this.colDeliveredVisible = false;
        break;
      case 'Credit Check':
        this.availableStages = ['Credit Check'];
        this.colCreatedVisible = false;
        this.colECDVisible = false;
        this.colContainerVisible = false;
        this.colETAVisible = false;
        this.colDeliveredVisible = false;
        break;
      case 'In Production':
        this.availableStages = ['Scheduled For Production', 'In Production', 'Production Complete'];
        this.colCreatedVisible = false;
        this.colContainerVisible = false;
        this.colETAVisible = false;
        this.colDeliveredVisible = false;
        break;
      case 'In Shipping':
        this.availableStages = ['In Shipping'];
        this.colCreatedVisible = false;
        this.colDeliveredVisible = false;
        break;
      case 'Delivered':
        this.availableStages = ['Delivered'];
        this.colCreatedVisible = false;
        this.colDeliveredVisible = false;
        break;
    }
  }

  componentWillUnmount() {
    // unsubscribe to ensure no memory leaks
    this.subscription.unsubscribe();
    if (this.typingTimer) {
      clearTimeout(this.typingTimer);
    }
  }

  handleError(error: string): void {
    console.log(error);
    if (!this.state.error) {
      this.toastService?.showError(this.toast, error);

      this.setState({error: true});
    }
  }

  async loadData() {
    this.setState({loading: true});
    const filters: string[] = [];

    if (this.state.filters.order_code) {
      filters.push(
        JSON.stringify({
          field: 'id',
          value: this.state.filters.order_code,
          condition: 'like',
        })
      );
    }

    if (this.state.filters.reference) {
      filters.push(
        JSON.stringify({
          field: 'reference',
          value: this.state.filters.reference,
          condition: 'iLike',
        })
      );
    }
    if (this.state.filters.company_name) {
      filters.push(
        JSON.stringify({
          orConditions: [
            {
              field: 'company.name',
              value: this.state.filters.company_name,
              condition: 'iLike',
            },
            {
              field: 'company.trading_as',
              value: this.state.filters.company_name,
              condition: 'iLike',
            },
          ],
        })
      );
    }
    if (this.state.filters.owner_ids && this.state.filters.owner_ids.length) {
      filters.push(
        JSON.stringify({
          field: "owner_contact_ref->>'user_id'",
          condition: 'in',
          value: this.state.filters.owner_ids,
        })
      );
    }
    if (this.state.filters.size) {
      filters.push(
        JSON.stringify({
          field: 'size',
          value: this.state.filters.size,
        })
      );
    }
    if (this.state.filters.summary) {
      filters.push(
        JSON.stringify({
          field: 'summary',
          value: this.state.filters.summary,
          condition: 'iLike',
        })
      );
    }
    //Stages
    if (this.state.filters.stage && this.state.filters.stage.length) {
      filters.push(
        JSON.stringify({
          field: 'stage',
          value: this.state.filters.stage,
          condition: 'in',
        })
      );
    } else {
      if (this.availableStages && this.availableStages.length > 0) {
        filters.push(
          JSON.stringify({
            field: 'stage',
            value: this.availableStages,
            condition: 'in',
          })
        );
      }
    }

    if (this.state.filters.ecd.fromDate) {
      const fromDate = this.state.filters.ecd.fromDate.toISODate();
      filters.push(
        JSON.stringify({
          field: 'factory_order.ecd',
          value: fromDate,
          condition: '>=',
        })
      );
    }
    if (this.state.filters.ecd.toDate) {
      const toDate = this.state.filters.ecd.toDate.toISODate();
      filters.push(
        JSON.stringify({
          field: 'factory_order.ecd',
          value: toDate,
          condition: '<=',
        })
      );
    }

    if (this.state.filters.approved_at.fromDate) {
      const fromDate = this.state.filters.approved_at.fromDate.toISODate();
      filters.push(
        JSON.stringify({
          field: 'approved_at',
          value: fromDate,
          condition: '>=',
        })
      );
    }
    if (this.state.filters.approved_at.toDate) {
      const toDate = this.state.filters.approved_at.toDate.toISODate();
      filters.push(
        JSON.stringify({
          field: 'approved_at',
          value: toDate,
          condition: '<=',
        })
      );
    }

    if (this.state.filters.created_at.fromDate) {
      const fromDate = this.state.filters.created_at.fromDate.toISODate();
      filters.push(
        JSON.stringify({
          field: 'created_at',
          value: fromDate,
          condition: '>=',
        })
      );
    }
    if (this.state.filters.created_at.toDate) {
      const toDate = this.state.filters.created_at.toDate.toISODate();
      filters.push(
        JSON.stringify({
          field: 'created_at',
          value: toDate,
          condition: '<=',
        })
      );
    }
    if (this.state.filters.submitted_at.fromDate) {
      const fromDate = this.state.filters.submitted_at.fromDate.toISODate();
      filters.push(
        JSON.stringify({
          field: 'submitted_at',
          value: fromDate,
          condition: '>=',
        })
      );
    }
    if (this.state.filters.submitted_at.toDate) {
      const toDate = this.state.filters.submitted_at.toDate.toISODate();
      filters.push(
        JSON.stringify({
          field: 'submitted_at',
          value: toDate,
          condition: '<=',
        })
      );
    }
    if (this.state.filters.approved_at.fromDate) {
      const fromDate = this.state.filters.approved_at.fromDate.toISODate();
      filters.push(
        JSON.stringify({
          field: 'approved_at',
          value: fromDate,
          condition: '>=',
        })
      );
    }
    if (this.state.filters.approved_at.toDate) {
      const toDate = this.state.filters.approved_at.toDate.toISODate();
      filters.push(
        JSON.stringify({
          field: 'approved_at',
          value: toDate,
          condition: '<=',
        })
      );
    }
    if (this.state.filters.ecd.fromDate) {
      const fromDate = this.state.filters.ecd.fromDate.toISODate();
      filters.push(
        JSON.stringify({
          field: 'factory_order.ecd',
          value: fromDate,
          condition: '>=',
        })
      );
    }
    if (this.state.filters.ecd.toDate) {
      const toDate = this.state.filters.ecd.toDate.toISODate();
      filters.push(
        JSON.stringify({
          field: 'factory_order.ecd',
          value: toDate,
          condition: '<=',
        })
      );
    }
    if (this.state.filters.container_id) {
      filters.push(
        JSON.stringify({
          field: 'freight_order.container_id',
          value: this.state.filters.container_id,
          condition: 'iLike',
        })
      );
    }
    if (this.state.filters.eta.fromDate) {
      const fromDate = this.state.filters.eta.fromDate.toISODate();
      filters.push(
        JSON.stringify({
          field: 'freight_order.eta',
          value: fromDate,
          condition: '>=',
        })
      );
    }
    if (this.state.filters.eta.toDate) {
      const toDate = this.state.filters.eta.toDate.toISODate();
      filters.push(
        JSON.stringify({
          field: 'freight_order.eta',
          value: toDate,
          condition: '<=',
        })
      );
    }
    if (this.state.filters.delivered_at.fromDate) {
      const fromDate = this.state.filters.delivered_at.fromDate.toISODate();
      filters.push(
        JSON.stringify({
          field: 'freight_order.delivered_at',
          value: fromDate,
          condition: '>=',
        })
      );
    }
    if (this.state.filters.delivered_at.toDate) {
      const toDate = this.state.filters.delivered_at.toDate.toISODate();
      filters.push(
        JSON.stringify({
          field: 'freight_order.delivered_at',
          value: toDate,
          condition: '<=',
        })
      );
    }

    this.setState({activeFilters: {...filters}});

    const sortBy = {
      field: this.state.sortBy?.field,
      direction: this.state.sortBy?.order === 1 ? 'ASC' : 'DESC',
    };
    switch (sortBy.field) {
      case 'order_code':
        sortBy.field = 'id';
        break;
      case 'ecd':
        sortBy.field = 'factory_order.ecd';
        break;
      case 'container_id':
        sortBy.field = 'freight_order.container_id';
        break;
      case 'eta':
        sortBy.field = 'container.eta_to_port';
        break;
      case 'delivered_at':
        sortBy.field = 'freight_order.delivered_at';
        break;
      case 'company_name':
        sortBy.field = 'company.name';
        break;
      case 'owner_contact_ref':
        sortBy.field = "owner_contact_ref->>'label'";
        break;
    }

    const sortByStringyField = JSON.stringify(sortBy.field ? sortBy : {field: 'factory_order.ecd', direction: 'ASC'});

    const params: QueryParameter = {
      offset: this.state.pagination.offset,
      page_size: this.state.pagination.pageSize,
      filters: filters,
      orderBys: [sortByStringyField],
      aggregate: true,
    };

    this.ordersService
      ?.getOrders(params)
      .then((data: ApiListResponse) => {
        const dataRecords = (data.records as Order[]) ?? [];
        this.handleSelectedItems(dataRecords);
        this.setState({
          items: dataRecords,
          totalItems: data.total_records ?? 0,
          loading: false,
        });
      })
      .catch(error => {
        this.handleError('Records load failed');
        this.setState({loading: false});
        console.log(error);
      });

    const contactParams: QueryParameter = {
      orderBys: [
        JSON.stringify({field: 'first_name', direction: 'ASC'}),
        JSON.stringify({field: 'last_name', direction: 'ASC'}),
      ],
    };

    this.contactsService
      ?.getContacts(contactParams)
      .then(data => {
        const contacts = (data.records as Contact[]) ?? [];
        const userRefs = contacts.map(contact => {
          return {
            label: `${contact.first_name} ${contact.last_name}`,
            user_id: contact.user_id,
            contact_id: contact.id,
          } as UserReference;
        });
        this.setState({
          availableOwners: userRefs,
        });
      })
      .catch(error => {
        console.log('Failed to load owners / contact.', error);
      });
  }

  async onPageChange(e: DataTablePageParams) {
    await this.setState({pagination: {offset: e.first, pageSize: e.rows}});
    this.loadData();
  }

  async onSort(e: DataTableSortParams) {
    await this.setState({sortBy: {field: e.sortField, order: e.sortOrder}});
    this.loadData();
  }

  async onFilterChange(
    e:
      | React.ChangeEvent<HTMLInputElement>
      | DropdownChangeParams
      | InputSwitchChangeParams
      | DateColumnFilterChangeEvent
  ) {
    const value = e.target.value;
    const name = e.target.name;

    await this.setState({
      filters: {
        ...this.state.filters,
        [name]: value,
      },
    });
    this.loadData();
  }

  handleSelectedItems(allItems: Order[]) {
    const selectedItems = [...this.state.selectedItems];
    const items: Order[] = allItems.filter(item => {
      return selectedItems.find(selectedItem => {
        return selectedItem.id === item.id;
      });
    });

    this.setChangeSelectedItems(items);
  }

  setChangeSelectedItems(items: Order[]) {
    this.setState({selectedItems: items});
  }

  async setChangeSelectedItem(item: Order) {
    const items = [...this.state.selectedItems];
    const existingItem = items.find(i => i.id === item.id);
    if (!existingItem) {
      items.push(item);
      await this.setState({selectedItems: items});
    }
  }

  showEditDialog(rowData: Order) {
    const orderData = rowData as Order;
    this.setState({editOrder: orderData, showEditDialog: true});
  }

  hideEditDialog = () => {
    this.setState({showEditDialog: false});
  };

  initMenuItems(rowData: Order): AppMenuItem[] {
    const menuItems: AppMenuItem[] = [];
    menuItems.push({
      label: 'Edit Order',
      faIcon: ['far', 'pencil'],

      template: (item: AppMenuItem, options: MenuItemOptions) => {
        return <AppMenuItemTemplate item={item} options={options} />;
      },
      command: () => {
        this.showEditDialog(rowData);
      },
    });

    if (
      this.usersService?.hasAnyPermission(['order-manage', 'order-submit-onbehalf']) &&
      (rowData.stage === 'Estimate' || rowData.stage === '00 Estimate')
    ) {
      menuItems.push({
        label: 'Submit On Behalf',
        faIcon: ['far', 'check'],

        template: (item: AppMenuItem, options: MenuItemOptions) => {
          return <AppMenuItemTemplate item={item} options={options} />;
        },
        command: () => {
          this.onSubmit(rowData);
        },
      });
    }
    /*
    if (
      this.usersService?.hasAnyPermission(['order-manage', 'order-approve']) &&
      (rowData.stage === 'New' || rowData.stage === '01 New')
    ) {
      menuItems.push({
        faIcon: ['far', 'check'],
        label: 'Approve',
        template: (item: AppMenuItem, options: MenuItemOptions) => {
          return <AppMenuItemTemplate item={item} options={options} />;
        },
        command: () => {
          this.handleStageChangeRequest('Scheduled For Production', rowData);
        },
      });
    }
    */

    if (
      rowData.stage === 'Drawing Notes Awaiting Approval' ||
      rowData.stage === 'Drawing Notes Awaiting Approval Re-Draw'
    ) {
      menuItems.push({
        faIcon: faFolderOpen,
        label: 'Approve drawing notes',
        template: (item: AppMenuItem, options: MenuItemOptions) => {
          return <AppMenuItemTemplate item={item} options={options} />;
        },
        command: () => {
          this.showDrawingDialog(rowData);
        },
      });
    }
    return menuItems;
  }

  showDrawingDialog(rowData: Order) {
    this.setState({
      selectedOrder: rowData,
      showDrawingsDialog: true,
    });
  }

  onHideDrawingDialog() {
    this.setState({showDrawingsDialog: false});
  }

  async onDrawingsSave(
    orderId: string,
    paOrderPatch: PaOrderPatch,
    tempFiles: Map<DrawingFileReference['index'], TempFile>
  ) {
    this.setState({loading: true});
    this.ordersService
      ?.updateOrder(orderId, paOrderPatch)
      .then(() => {
        this.toastService?.showSuccess(this.toast, `Order ${orderId} updated successfully.`);
        this.setState({showDrawingsDialog: false});
      })
      .catch(error => {
        this.handleError(`${orderId} update failed`);
        console.error('error: ' + error);
      })
      .finally(() => {
        MessageService.sendMessage(messages.orderUpdated);
      });
  }

  /**
   * Method moves the order to the specified stage.
   * @param newStage
   */
  async handleStageChangeRequest(newStage: OrderStage, order: Order) {
    const orderPatch: OrderPatch = {
      stage: newStage,
    };

    orderPatch.tles_success = [
      {
        event_type: 'stage_transition',
        entity_id: order.id,
        entity_type: 'order',
        recorded_at: new Date(),
        recorded_by: localStorage.getItem('current_user'),
        content: {
          old_stage: order.stage,
          new_stage: newStage,
        } as TleContentStageTransition,
      },
    ] as TimeLineEvent[];
    this.ordersService
      ?.updateOrder(order.id ?? '', orderPatch)
      .then(() => {
        this.toastService?.showSuccess(this.toast, `Stage for ${order.id} ${order.reference} changed to ${newStage}.`);
        this.loadData();
      })
      .catch(error => {
        this.toastService?.showError(
          this.toast,
          `Sorry, updating order stage for ${order.id} ${order.reference} to ${newStage} failed.`
        );
        console.error(error);
      });
  }

  async onSubmit(order: Order) {
    this.setState({showSubmitOrderDialog: true, editOrder: order as Order});
  }

  onHideSubmitConfirmDialog() {
    this.setState({showSubmitOrderDialog: false});
  }

  codeBodyTemplate(rowData: Order) {
    return (
      <React.Fragment>
        <AppColumnMenuBodyTemplate
          key={rowData.id}
          rowItemIdentifier={rowData?.id?.toString() ?? ''}
          isDynamicMenuItems={true}
          initMenuItems={() => this.initMenuItems(rowData)}
          selectedItems={this.state.selectedItems}
          handleChangeSelectedItems={() => this.setChangeSelectedItem(rowData)}
        >
          <NavLink to={'/order/' + rowData.id}>{rowData.id}</NavLink>
        </AppColumnMenuBodyTemplate>
      </React.Fragment>
    );
  }

  stageBodyTemplate(rowData: Order) {
    return (
      <span className={`stage-badge stage-${rowData.stage.toLowerCase().replaceAll(' ', '-').replaceAll(/[()]/g, '')}`}>
        {rowData.stage}
      </span>
    );
  }

  ownerBodyTemplate(rowData: Order) {
    let ownerName;
    if (rowData.owner_company?.trading_as) {
      ownerName = `${rowData.owner_company?.trading_as} (${rowData.owner_company?.name})`;
    } else {
      ownerName = rowData.owner_company?.name;
    }
    return (
      <>
        <span>{ownerName}</span>
      </>
    );
  }

  ownerContactBodyTemplate(rowData: Order) {
    return <span>{rowData.owner ?? ''}</span>;
  }

  approvedAtBodyTemplate(rowData: Order) {
    const formated_approved_on = rowData.approved_at
      ? DateTime.fromISO(rowData.approved_at.toString()).toFormat(formats.date)
      : '';
    const id = `approved-at-${rowData.id}`;
    return (
      <>
        <Tooltip target={`#${id}`}>
          {formated_approved_on} {rowData.approved_by}
        </Tooltip>
        <span id={id}>
          {formated_approved_on} {this.getInitials(rowData.approved_by)}
        </span>
      </>
    );
  }
  submittedAtBodyTemplate(rowData: Order) {
    const formated_submitted = rowData.submitted_at
      ? DateTime.fromISO(rowData.submitted_at.toString()).toFormat(formats.date)
      : '';
    const id = `sumitted-at-${rowData.id}`;
    return (
      <>
        <Tooltip target={`#${id}`}>
          {formated_submitted} {rowData.submitted_by}
        </Tooltip>
        <span id={id}>
          {formated_submitted} {this.getInitials(rowData.submitted_by)}
        </span>
      </>
    );
  }
  createdAtBodyTemplate(rowData: Order) {
    const formattedDate = rowData.created_at
      ? DateTime.fromISO(rowData.created_at.toString()).toFormat(formats.date)
      : '';
    const formattedDateTime = rowData.created_at
      ? DateTime.fromISO(rowData.created_at.toString()).toFormat(formats.dateTime)
      : '';
    const id = `created-at-${rowData.id}`;
    const fullName = rowData.created_by?.full_name ?? '';
    const intials = this.getInitials(fullName);
    const tooltipName = rowData.created_by?.type === 'two' ? intials : fullName;
    return (
      <>
        <Tooltip target={`#${id}`}>
          {formattedDateTime} {tooltipName}
        </Tooltip>
        <span id={id}>
          {formattedDate} {intials}
        </span>
      </>
    );
  }

  getInitials(fullName: string | undefined): string {
    if (fullName?.length) {
      return fullName
        .split(' ')
        .map(name => name[0])
        .join('');
    }
    return '';
  }

  ecdBodyTemplate(rowData: Order) {
    const formattedEcd = rowData.factory_order?.ecd
      ? DateTime.fromISO(rowData.factory_order?.ecd.toString()).toFormat(formats.date)
      : '';
    return <span>{formattedEcd}</span>;
  }

  deliveredBodyTemplate(rowData: Order) {
    const formattedDeliveredAt = rowData.freight_order?.delivered_at
      ? DateTime.fromISO(rowData.freight_order?.delivered_at.toString()).toFormat(formats.date)
      : '';
    return <span>{formattedDeliveredAt}</span>;
  }

  handleFilterChange = (
    event: React.ChangeEvent<HTMLInputElement> | DropdownChangeParams | InputSwitchChangeParams
  ) => {
    if (this.typingTimer) {
      clearTimeout(this.typingTimer);
    }
    this.typingTimer = setTimeout(() => {
      this.onFilterChange(event);
    }, 1000);
  };

  async onFilterOwnerChange(e: DropdownChangeParams) {
    await this.setState({
      filters: {
        ...this.state.filters,
        owner_ids: e.value,
      },
    });
    this.loadData();
  }

  availableStageOptions() {
    return this.availableStages
      ? this.availableStages.map(o => {
          const option: DropdownOption = {label: o, value: o};
          return option;
        })
      : [];
  }

  availableOwnerOptions() {
    return this.state.availableOwners
      ? this.state.availableOwners.map(owner => {
          const option: DropdownOption = {
            label: owner.label ?? '',
            value: owner.user_id!,
          };
          return option;
        })
      : [];
  }

  render() {
    const {editOrder, selectedOrder, showDrawingsDialog} = this.state;
    const codeFilter = (
      <InputText
        name="order_code"
        className="form-filter"
        onChange={e => {
          this.handleFilterChange(e);
        }}
      />
    );
    const referenceFilter = (
      <InputText
        name="reference"
        className="form-filter"
        onChange={e => {
          this.handleFilterChange(e);
        }}
      />
    );
    const ownerFilter = (
      <InputText
        name="company_name"
        className="form-filter"
        onChange={e => {
          this.handleFilterChange(e);
        }}
      />
    );

    const selectedOwnerTemplate = (value: string) => {
      if (value) {
        const selectedRef = this.state.availableOwners.find(owner => owner.user_id! === value);
        if (selectedRef) {
          return <span>{selectedRef.label}</span>;
        }
      }

      return <></>;
    };

    const ownerOptionTemplate = (option: DropdownOption) => {
      const optionRef = this.state.availableOwners.find(owner => owner.user_id! === option.value);
      if (optionRef) {
        return <span>{optionRef.label}</span>;
      }

      return <></>;
    };

    const ownerContactFilter = (
      <MultiSelect
        selectedItemTemplate={selectedOwnerTemplate}
        itemTemplate={ownerOptionTemplate}
        value={this.state.filters.owner_ids}
        options={this.availableOwnerOptions()}
        name="owner"
        filter
        className="form-filter"
        onChange={e => {
          this.onFilterOwnerChange(e);
        }}
        showClear
      />
    );

    const selectedItemTemplate = (value: string) => {
      if (value) {
        return (
          <span
            className={`p-mr-1 stage-badge stage-badge-filter stage-${value
              .toLowerCase()
              .replaceAll(' ', '-')
              .replaceAll(/[()]/g, '')}`}
          >
            {value}
          </span>
        );
      }

      return <></>;
    };

    const itemTemplate = (option: DropdownOption) => {
      return (
        <span
          className={`stage-badge stage-${(option.value as string)
            .toLowerCase()
            .replaceAll(' ', '-')
            .replaceAll(/[()]/g, '')}`}
        >
          {option.value}
        </span>
      );
    };

    const stageFilter = (
      <MultiSelect
        selectedItemTemplate={selectedItemTemplate}
        itemTemplate={itemTemplate}
        value={this.state.filters.stage}
        options={this.availableStageOptions()}
        name="stage"
        className="form-filter stage-filter"
        onChange={e => {
          this.onFilterChange(e);
        }}
        showClear
      />
    );

    const approvedAtFilter = (
      <DateColumnFilter
        name="approved_at"
        value={this.state.filters.approved_at}
        onChange={e => this.onFilterChange(e)}
      />
    );

    const createdAtFilter = (
      <DateColumnFilter
        name="created_at"
        value={this.state.filters.created_at}
        onChange={e => this.onFilterChange(e)}
      />
    );

    const submittedAtFilter = (
      <DateColumnFilter
        name="submitted_at"
        value={this.state.filters.submitted_at}
        onChange={e => this.onFilterChange(e)}
      />
    );

    const etaFilter = (
      <DateColumnFilter name="eta" value={this.state.filters.eta} onChange={e => this.onFilterChange(e)} />
    );

    const deliveredFilter = (
      <DateColumnFilter
        name="delivered_at"
        value={this.state.filters.delivered_at}
        onChange={e => this.onFilterChange(e)}
      />
    );

    const ecdFilter = (
      <DateColumnFilter name="ecd" value={this.state.filters.ecd} onChange={e => this.onFilterChange(e)} />
    );

    const sizeFilter = (
      <InputText
        name="size"
        className="form-filter"
        onChange={e => {
          this.handleFilterChange(e);
        }}
      />
    );

    const summaryFilter = (
      <InputText
        name="summary"
        className="form-filter"
        onChange={e => {
          this.handleFilterChange(e);
        }}
      />
    );

    const containerFilter = (
      <InputText
        name="container_id"
        className="form-filter"
        onChange={e => {
          this.handleFilterChange(e);
        }}
      />
    );

    return (
      <div id="order_list_page_container" className={'page-container'}>
        <TwoDataTable
          pageSizeIdentifier={'order_list_page_container'}
          selectedItems={[]}
          rows={this.state.pagination.pageSize}
          first={this.state.pagination.offset}
          sortField={this.state.sortBy?.field}
          sortOrder={this.state.sortBy?.order}
          onPage={e => this.onPageChange(e as DataTablePageParams)}
          onSort={e => this.onSort(e)}
          handleChangeSelectedItems={items => this.setChangeSelectedItems(items as Order[])}
          loading={this.state.loading}
          value={this.state.items}
          totalRecords={this.state.totalItems}
          activeFilters={this.state.activeFilters}
        >
          <Column
            header="Order Code"
            field="id"
            body={this.codeBodyTemplate}
            filter
            filterElement={codeFilter}
            sortable
            style={{width: '180px'}}
            showFilterMenu={false}
          />
          <Column
            header="Reference"
            field="reference"
            filter
            filterElement={referenceFilter}
            sortable
            style={{width: '360px'}}
            showFilterMenu={false}
          />
          <Column
            header="Owner"
            field="company_name"
            body={this.ownerBodyTemplate}
            filter
            filterElement={ownerFilter}
            sortable
            style={{width: ' 180px'}}
            showFilterMenu={false}
          />
          <Column
            header="Contact"
            field="owner_contact_ref"
            body={this.ownerContactBodyTemplate}
            filter
            filterElement={ownerContactFilter}
            sortable
            style={{width: '160px'}}
            showFilterMenu={false}
          />
          <Column
            header="Size"
            field="size"
            filter
            filterElement={sizeFilter}
            sortable
            style={{width: '80px'}}
            showFilterMenu={false}
          />
          <Column
            header="Summary"
            field="summary"
            filter
            filterElement={summaryFilter}
            sortable
            style={{width: '180px'}}
            showFilterMenu={false}
          />
          <Column
            header="Stage"
            field="stage"
            body={this.stageBodyTemplate}
            filter
            filterElement={stageFilter}
            sortable
            style={{width: '180px'}}
            showFilterMenu={false}
          />
          <Column
            header="Created"
            field="created_at"
            body={this.createdAtBodyTemplate}
            filter
            filterElement={createdAtFilter}
            sortable
            hidden={!this.colCreatedVisible}
            style={{width: '80px'}}
            showFilterMenu={false}
          />
          <Column
            header="Submitted"
            field="submitted_at"
            body={this.submittedAtBodyTemplate}
            filter
            filterElement={submittedAtFilter}
            sortable
            hidden={!this.colSubmittedVisible}
            style={{width: '80px'}}
            showFilterMenu={false}
          />
          <Column
            header="Approved"
            field="approved_at"
            body={this.approvedAtBodyTemplate}
            filter
            filterElement={approvedAtFilter}
            sortable
            hidden={!this.colApprovedVisible}
            style={{width: '80px'}}
            showFilterMenu={false}
          />
          <Column
            header="ECD"
            field="ecd"
            body={this.ecdBodyTemplate}
            filter
            filterElement={ecdFilter}
            sortable
            hidden={!this.colECDVisible}
            style={{width: '80px'}}
            showFilterMenu={false}
          />
          <Column
            header="Container"
            field="container_id"
            filter
            filterElement={containerFilter}
            sortable
            hidden={!this.colContainerVisible}
            style={{width: '180px'}}
            showFilterMenu={false}
          />
          <Column
            header="ETA"
            field="eta"
            body={this.ecdBodyTemplate} // TODO edit to etaBodyTamplate
            filter
            filterElement={etaFilter}
            sortable
            hidden={!this.colETAVisible}
            style={{width: '80px'}}
            showFilterMenu={false}
          />
          <Column
            header="Delivered"
            field="delivered_at"
            body={this.deliveredBodyTemplate}
            filter
            filterElement={deliveredFilter}
            sortable
            hidden={!this.colDeliveredVisible}
            style={{width: '80px'}}
            showFilterMenu={false}
          />
        </TwoDataTable>
        <Toast ref={this.toast} />

        <AddEditOrderDialog
          toast={this.toast}
          showDialog={this.state.showEditDialog}
          onHide={this.hideEditDialog}
          order={editOrder}
        />
        <SubmitOrderConfirmDialog
          showDialog={this.state.showSubmitOrderDialog}
          onHide={this.onHideSubmitConfirmDialog}
          toast={this.toast}
          order={editOrder}
          orderPatch={{}}
        />
        {showDrawingsDialog &&
          selectedOrder &&
          (selectedOrder.stage === 'Drawing Notes Awaiting Approval' ||
            selectedOrder.stage === 'Drawing Notes Awaiting Approval Re-Draw') && (
            <DrawingsDialog
              mode={'note_review'}
              onSave={this.onDrawingsSave}
              order={selectedOrder as PaOrder}
              showDialog={showDrawingsDialog}
              onHide={this.onHideDrawingDialog}
              loading={this.state.loading}
            />
          )}
      </div>
    );
  }
}

export default OrderListComponent;
