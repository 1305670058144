import {Column} from 'primereact/column';
import {DataTablePageParams, DataTableSortOrderType, DataTableSortParams} from 'primereact/datatable';
import {InputText} from 'primereact/inputtext';
import {MultiSelectChangeParams} from 'primereact/multiselect';
import {Toast} from 'primereact/toast';
import React from 'react';
import {Subscription} from 'rxjs';
import {AppContext, AppMenuItem, ToastService, TwoDataTable} from 'two-app-ui';
import {QueryParameter} from 'two-core';
import {values} from '../../config/values';

interface State {
  loading: boolean;
  total_items: number;
  activeFilters: {};
  filters: {
    name: string;
    type: string;
    upload: string;
  };
  pagination: {
    pageSize: number;
    offset: number;
  };
  sortBy: {
    field: string;
    order: DataTableSortOrderType;
  } | null;
}

class OrderFiles extends React.Component<{}, State> {
  static contextType = AppContext;
  toastService: ToastService | null = null;
  subscription: Subscription = new Subscription();
  toast: React.RefObject<Toast>;
  typingTimer: NodeJS.Timeout | undefined = undefined;

  constructor(props: {}) {
    super(props);
    this.state = {
      loading: false,
      total_items: 0,
      activeFilters: {},
      filters: {
        name: '',
        type: '',
        upload: '',
      },
      pagination: {
        pageSize: 10,
        offset: 0,
      },
      sortBy: null,
    };

    this.toast = React.createRef();

    this.onPageChange = this.onPageChange.bind(this);
    this.onSort = this.onSort.bind(this);
  }

  componentDidMount() {
    this.toastService = this.context.toastService;

    this.loadData();
  }

  componentWillUnmount() {
    this.subscription.unsubscribe();
    if (this.typingTimer) {
      clearTimeout(this.typingTimer);
    }
  }

  loadData() {
    this.setState({loading: true});
    const filters: string[] = [];

    if (this.state.filters.name) {
      filters.push(
        JSON.stringify({
          field: 'name',
          value: this.state.filters.name,
          condition: 'like',
        })
      );
    }

    if (this.state.filters.type) {
      filters.push(
        JSON.stringify({
          field: 'type',
          value: this.state.filters.type,
          condition: 'iLike',
        })
      );
    }
    if (this.state.filters.upload) {
      filters.push(
        JSON.stringify({
          field: 'upload',
          value: this.state.filters.upload,
          condition: 'iLike',
        })
      );
    }

    this.setState({activeFilters: {...filters}});

    const sortBy = JSON.stringify({
      field: this.state.sortBy?.field ?? 'title',
      direction: this.state.sortBy?.order === 1 ? 'ASC' : 'DESC',
    });

    const params: QueryParameter = {
      offset: this.state.pagination.offset,
      page_size: this.state.pagination.pageSize,
      orderBys: [sortBy],
      aggregate: true,
      filters: filters,
    };

    // load files service
  }

  initMenuItems(): AppMenuItem[] {
    const menuItems: AppMenuItem[] = [];

    //
    return menuItems;
  }

  async onPageChange(e: DataTablePageParams) {
    await this.setState({pagination: {offset: e.first, pageSize: e.rows}});
    this.loadData();
  }

  async onSort(e: DataTableSortParams) {
    await this.setState({sortBy: {field: e.sortField, order: e.sortOrder}});
    this.loadData();
  }

  async onFilterChange(e: React.ChangeEvent<HTMLInputElement> | MultiSelectChangeParams) {
    const value = e.target.value;
    const name = e.target.name;

    await this.setState({
      filters: {
        ...this.state.filters,
        [name]: value,
      },
    });
    this.loadData();
  }

  handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (this.typingTimer) {
      clearTimeout(this.typingTimer);
    }
    this.typingTimer = setTimeout(() => {
      this.onFilterChange(event);
    }, values.stopTypingDetection);
  };

  render() {
    const name = (
      <InputText
        name="name"
        className="form-filter"
        onChange={e => {
          this.handleFilterChange(e);
        }}
      />
    );
    const type = (
      <InputText
        name="type"
        className="form-filter"
        onChange={e => {
          this.handleFilterChange(e);
        }}
      />
    );
    const upload = (
      <InputText
        name="upload"
        className="form-filter"
        onChange={e => {
          this.handleFilterChange(e);
        }}
      />
    );

    return (
      <div className="p-d-flex p-p-2" style={{height: '100%'}}>
        <div className="page-container">
          <TwoDataTable
            style={{height: '100%'}}
            pageSizeIdentifier={''}
            heightToScroll={undefined}
            selectedItems={[]}
            loading={this.state.loading}
            rows={this.state.pagination.pageSize}
            first={this.state.pagination.offset}
            activeFilters={this.state.activeFilters}
            value={undefined}
            totalRecords={this.state.total_items}
            onPage={e => this.onPageChange(e as DataTablePageParams)}
            onSort={e => this.onSort(e)}
            sortField={this.state.sortBy?.field}
            sortOrder={this.state.sortBy?.order}
            initMenuItems={() => this.initMenuItems()}
          >
            <Column
              header="Name"
              body={name}
              filter
              filterElement={name}
              sortable
              style={{width: '170px'}}
              showFilterMenu={false}
            />
            <Column
              header="Type"
              field="type"
              filter
              filterElement={type}
              sortable
              style={{width: '170px'}}
              showFilterMenu={false}
            />
            <Column
              header="Upload"
              field="upload"
              filter
              filterElement={upload}
              sortable
              style={{width: '170px'}}
              showFilterMenu={false}
            />
          </TwoDataTable>
          <Toast ref={this.toast} />
        </div>
      </div>
    );
  }
}

export default OrderFiles;
