import React from 'react';
import OrdersService from '../../services/OrdersService';
import {withRouter, RouteComponentProps} from 'react-router-dom';
import {
  AppContext,
  TwoAction,
  TwoEntityPanel,
  TwoTimeline,
  TwoTimelineItem,
  ToastService,
  TwoEntityComponent,
  MessageService,
  UsersService,
  OrderItemsComponent,
} from 'two-app-ui';
import './Order.scss';
import OrderDetail from './OrderDetail';
import {
  Field,
  Order,
  OrderItem,
  QueryParameter,
  TimeLineEvent,
  OrderStage,
  OrderPatch,
  TleContentStageTransition,
  PaProductDefinition,
} from 'two-core';
import TleService from '../../services/TleService';
import {faCalendarAlt, faPencil, faBarcodeRead, faCheck, faFolderOpen} from '@fortawesome/pro-regular-svg-icons';
import {Toast} from 'primereact/toast';
import {ProgressSpinner} from 'primereact/progressspinner';
import {messages} from '../../config/messages';
import OrderEditDialog from './AddEditOrderDialog';
import {Subscription} from 'rxjs';
import ProductsService from '../../services/ProductsService';
import SubmitOrderConfirmDialog from './SubmitOrderConfirmDialog';
import OrderFiles from './OrderFiles';

interface RouteProps {
  id: string;
}

interface State {
  loading: boolean;
  order: Order;
  events: TimeLineEvent[];
  items: TwoTimelineItem[];
  showEditDialog: boolean;
  productDefinitions: PaProductDefinition[];
  showSubmitOrderDialog: boolean;
}

class OrderComponent extends React.Component<RouteComponentProps<RouteProps>, State> {
  static contextType = AppContext;
  ordersService: OrdersService | null = null;
  tleService: TleService | null = null;
  toastService: ToastService | null = null;
  usersService: UsersService | null = null;
  productsService: ProductsService | null = null;

  subscription: Subscription = new Subscription();
  toast: React.RefObject<Toast>;

  constructor(props: RouteComponentProps<RouteProps>) {
    super(props);
    this.ordersService = null;
    this.tleService = null;

    this.state = {
      showSubmitOrderDialog: false,
      loading: false,
      order: new Order({}),
      events: [],
      items: [],
      showEditDialog: false,
      productDefinitions: [],
    };

    this.toast = React.createRef();

    this.loadEvents = this.loadEvents.bind(this);
    this.hideEditDialog = this.hideEditDialog.bind(this);
    this.onHideSubmitConfirmDialog = this.onHideSubmitConfirmDialog.bind(this);
  }

  componentDidMount() {
    this.ordersService = this.context.ordersService;
    this.tleService = this.context.tleService;
    this.toastService = this.context.toastService;
    this.usersService = this.context.usersService;
    this.productsService = this.context.productsService;

    this.loadData();

    this.subscription = MessageService.getMessage().subscribe(message => {
      if (message === messages.orderUpdated) {
        this.loadData();
      }
    });
  }

  componentWillUnmount() {
    // unsubscribe to ensure no memory leaks
  }

  loadData() {
    this.setState({loading: true});
    const id = this.props.match.params.id;
    const loads = [];
    loads.push(this.loadOrder(id));
    loads.push(this.loadEvents(id));

    Promise.all(loads)
      .catch(error => {
        console.error('Failed Loading Data:' + error);
        this.toastService?.showError(this.toast, 'Failed loading data, please refresh and try again.');
      })
      .finally(() => {
        this.setState({loading: false});
      });
  }

  async loadOrder(orderId: string) {
    const filters: string[] = [];
    filters.push(
      JSON.stringify({
        field: 'id',
        value: orderId,
      })
    );

    const params: QueryParameter = {
      filters: filters,
      aggregate: true,
    };
    return this.ordersService?.getOrders(params).then(data => {
      const order = (data.records as Order[])[0];
      const orderItems: OrderItem[] = order.items.map(item => {
        const fields: Field[] = item.fields.map(field => {
          //we must convert field to class object
          const values = field.values.map(fieldValue => {
            if (fieldValue.sub_fields) {
              fieldValue.sub_fields = fieldValue.sub_fields.map(subField => {
                //we must convert subfield to class object
                return new Field(subField);
              });
            }
            return fieldValue;
          });
          return new Field({...field, values: values});
        });
        item.fields = fields;
        return new OrderItem(item);
      });
      order.items = orderItems;
      this.setState({
        order: order,
      });
      if (order.revision_id && order.owner) {
        return this.loadProductDefinitions(order.revision_id, order.owner);
      }
      return;
    });
  }

  async loadProductDefinitions(revisionId: number, companyId: string) {
    return this.productsService?.getProductsDefinitionsForCompany(companyId, revisionId).then(data => {
      const productDefinitions = (data.records as PaProductDefinition[]) ?? [];
      this.setState({
        productDefinitions: productDefinitions,
      });
    });
  }

  async loadEvents(id: string) {
    const filters: string[] = [
      JSON.stringify({
        field: 'entity_type',
        value: 'order',
      }),
      JSON.stringify({
        field: 'entity_id',
        value: id,
      }),
    ];
    const orderBys = JSON.stringify({field: 'recorded_at', direction: 'DESC'});
    const params: QueryParameter = {
      filters: filters,
      orderBys: [orderBys],
      aggregate: true,
    };
    return this.tleService?.getTimeLineEvents(params).then(data => {
      const events = data.records as TimeLineEvent[];

      const items = events.map(event => {
        const item: TwoTimelineItem = {event: event};
        return item;
      });

      this.setState({
        events: events,
        items: items,
      });
    });
  }

  getActions(): TwoAction[] {
    const actions: TwoAction[] = [];
    if (this.usersService?.hasPermission('order-manage') && this.state.order.stage !== 'Delivered') {
      actions.push({
        icon: faPencil,
        label: 'Edit',
        main: true,
        action: () => {
          this.setState({showEditDialog: true});
        },
      });
    }
    /*
    if (
      this.usersService?.hasAnyPermission(['order-manage', 'order-approve']) &&
      (this.state.order.stage === 'New' || this.state.order.stage === '01 New')
    ) {
      actions.push({
        icon: faCheck,
        label: 'Approve',
        action: () => {
          this.handleStageChangeRequest('Scheduled For Production');
        },
      });
    }
*/
    if (
      this.usersService?.hasAnyPermission(['order-manage', 'orders-submit-onbehalf']) &&
      (this.state.order.stage === 'Estimate' || this.state.order.stage === '00 Estimate')
    ) {
      actions.push({
        icon: faCheck,
        label: 'Submit on Behalf',
        action: () => {
          this.onSubmit();
        },
      });
    }

    return actions;
  }

  async onSubmit() {
    this.setState({showSubmitOrderDialog: true});
  }

  onHideSubmitConfirmDialog() {
    this.setState({showSubmitOrderDialog: false});
  }

  /**
   * Method moves the order to the specified stage.
   * @param newStage
   */
  async handleStageChangeRequest(newStage: OrderStage) {
    const {order} = this.state;
    const orderPatch: OrderPatch = {
      stage: newStage,
    };

    orderPatch.tles_success = [
      {
        event_type: 'stage_transition',
        entity_id: order.id,
        entity_type: 'order',
        recorded_at: new Date(),
        recorded_by: localStorage.getItem('current_user'),
        content: {
          old_stage: order.stage,
          new_stage: newStage,
        } as TleContentStageTransition,
      },
    ] as TimeLineEvent[];
    this.updateOrder(orderPatch, order)
      .then(() => {
        this.toastService?.showSuccess(this.toast, `Stage for ${order.id} ${order.reference} changed to ${newStage}.`);
      })
      .catch(error => {
        this.toastService?.showError(
          this.toast,
          `Sorry, updating order stage for ${order.id} ${order.reference} to ${newStage} failed.`
        );
        console.error(error);
      })
      .finally(() => {
        this.loadOrder(this.state.order.id!);
      });
  }

  async updateOrder(orderPatch: OrderPatch, order: Order) {
    return this.ordersService?.updateOrder(order.id ?? '', orderPatch).then(() => {
      this.loadEvents(this.state.order.id ?? '');
    });
  }

  showEditDialog(order: Order) {
    this.setState({order: order, showEditDialog: true});
  }

  hideEditDialog() {
    this.setState({showEditDialog: false});
  }

  render() {
    const {order, items, loading} = this.state;

    if (loading) {
      return (
        <div className="p-d-flex p-ai-center w-100 h-100">
          <ProgressSpinner />
        </div>
      );
    }

    return order.id ? (
      <>
        <TwoEntityComponent title={order.id} actions={this.getActions()}>
          <TwoEntityPanel isPrimary={true}>
            <OrderDetail order={order} />
          </TwoEntityPanel>
          <TwoEntityPanel label="Content" icon={faBarcodeRead} tooltip="Content">
            <>
              {!!order.items.length && (
                <OrderItemsComponent
                  mode={'readonly'}
                  items={order.items}
                  productDefinitions={this.state.productDefinitions}
                  onItemsChanged={() => {}}
                />
              )}
            </>
          </TwoEntityPanel>
          <TwoEntityPanel label="Timeline" icon={faCalendarAlt} tooltip="Timeline">
            <TwoTimeline key={order.id} items={items} />
          </TwoEntityPanel>
          <TwoEntityPanel label="Files" icon={faFolderOpen} tooltip="Files">
            <OrderFiles />
          </TwoEntityPanel>
        </TwoEntityComponent>

        <OrderEditDialog
          toast={this.toast}
          showDialog={this.state.showEditDialog}
          onHide={this.hideEditDialog}
          order={order}
        />

        <SubmitOrderConfirmDialog
          showDialog={this.state.showSubmitOrderDialog}
          onHide={this.onHideSubmitConfirmDialog}
          toast={this.toast}
          order={this.state.order}
          orderPatch={{}}
        />

        <Toast ref={this.toast} />
      </>
    ) : (
      <></>
    );
  }
}

export default withRouter(OrderComponent);
