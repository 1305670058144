import {FittingType, OrderStage} from 'two-core';

export const companyStages: string[] = ['Prospective', 'Pending Activation', 'Awaiting Approval', 'Active', 'Inactive'];
export const companyStates: string[] = ['ACT', 'NSW', 'NT', 'NZ', 'QLD', 'SA', 'TAS', 'VIC', 'WA'];
export const fittingTypes: FittingType[] = ['internal', '3rd party', 'made2fit'];
export const businessEntityTypes: string[] = ['Company Pty Ltd', 'Partnership', 'Sole Trader', 'Trust Company'];
export const orderStages: OrderStage[] = [
  'Estimate',
  'Repair Estimate',
  'New',
  'Drawing(s) Required',
  'Drawing Notes Awaiting Approval',
  'Drawing Notes Awaiting Approval Re-Draw',
  'Drawing(s) Awaiting Approval',
  'Re-Draw',
  'Drawing(s) Approved',
  'Credit Check',
  'Scheduled For Production',
  'In Production',
  'Production Complete',
  'In Shipping',
  'Delivered',
  'Cancelled',
];

export const contactRoles: string[] = ['admin', 'sales representative', 'fitter'];
export const companyLocationTypes: string[] = ['dealership', 'end customer'];
export const values = {
  stopTypingDetection: 1000,
};
